import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';
import aws from '../assets/aws.png';
import deltahacks from '../assets/deltahacks.png'
import roblox from '../assets/roblox.png'
import mcmaster from '../assets/mcmasterlogo.jpg'
import summer from '../assets/summer.png'
import resume from '../assets/resume.pdf'
import catphoto from '../assets/catphoto.JPG'
import blankit from '../assets/blankit.png'
import uber from '../assets/uber.pdf'
import uberphoto from '../assets/uberphoto.png'
import azure from '../assets/azure.png'
import linkedin from '../assets/linkedin.png'
import frcrobot from '../assets/FRCRobot.pdf'
import design from '../assets/Design.pdf'

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import profilepicture from '../assets/profilepicture.jpg';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import cohere from '../assets/cohere.png';


import adidas from '../assets/adidas.png';
import amazon from '../assets/amazon.png';
import asus from '../assets/asus.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';


export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  aws,
  deltahacks,
  roblox,
  mcmaster,
  summer,
  resume,
  catphoto,
  blankit,
  uber,
  uberphoto,
  azure,
  linkedin,
  frcrobot,
  design,
  about01,
  about02,
  about03,
  about04,
  profile,
  profilepicture,
  circle,
  logo,
  cohere,
  adidas,
  amazon,
  asus,
  bolt,
  nb,
  skype,
  spotify,
};